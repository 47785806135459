
import { importSync as i } from '@embroider/macros';
let d = window.define;
import "@ember-data/json-api/-embroider-implicit-modules.js";
import "@ember-data/store/-embroider-implicit-modules.js";
import "@ember/test-helpers/-embroider-implicit-modules.js";
import "@glimmer/component/-embroider-implicit-modules.js";
import "ember-can/-embroider-implicit-modules.js";
import "ember-cli-babel/-embroider-implicit-modules.js";
import "ember-cli-mirage/-embroider-implicit-modules.js";
import "ember-click-outside/-embroider-implicit-modules.js";
import "ember-concurrency/-embroider-implicit-modules.js";
import "ember-data/-embroider-implicit-modules.js";
import "ember-modifier/-embroider-implicit-modules.js";
import "ember-page-title/-embroider-implicit-modules.js";
import "ember-qunit/-embroider-implicit-modules.js";
import "ember-source/-embroider-implicit-modules.js";
import "ember-truth-helpers/-embroider-implicit-modules.js";
import "tracked-built-ins/-embroider-implicit-modules.js";
import "ember-cli-inject-live-reload/-embroider-implicit-modules.js";
import "@embroider/macros/-embroider-implicit-modules.js";
import "@ember/legacy-built-in-components/-embroider-implicit-modules.js";
import "@ember/optional-features/-embroider-implicit-modules.js";
import "@ember/string/-embroider-implicit-modules.js";
import "ember-auto-import/-embroider-implicit-modules.js";
import "ember-template-imports/-embroider-implicit-modules.js";
import "ember-cli-code-coverage/-embroider-implicit-modules.js";
import "@embroider/util/-embroider-implicit-modules.js";
import "@glimmer/tracking/-embroider-implicit-modules.js";
import "ember-cli-htmlbars/-embroider-implicit-modules.js";
import "@storybook/ember-cli-storybook/-embroider-implicit-modules.js";
import "ember-a11y-testing/-embroider-implicit-modules.js";
import "ember-arg-types/-embroider-implicit-modules.js";
import "ember-cli-clean-css/-embroider-implicit-modules.js";
import "ember-cli-dependency-checker/-embroider-implicit-modules.js";
import "ember-test-selectors/-embroider-implicit-modules.js";
import "ember-cli-deprecation-workflow/-embroider-implicit-modules.js";
import "ember-cli-showdown/-embroider-implicit-modules.js";
import "broccoli-asset-rev/-embroider-implicit-modules.js";
import "ember-cli-terser/-embroider-implicit-modules.js";
import "ember-cli-sri/-embroider-implicit-modules.js";
import "ember-cli-string-helpers/-embroider-implicit-modules.js";
import "ember-copy/-embroider-implicit-modules.js";
import "ember-feature-flags/-embroider-implicit-modules.js";
import "ember-intl/-embroider-implicit-modules.js";
import "ember-metrics/-embroider-implicit-modules.js";
import "ember-promise-helpers/-embroider-implicit-modules.js";
import "loader.js/-embroider-implicit-modules.js";
import "service-worker/-embroider-implicit-modules.js";
